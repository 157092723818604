import { PLAN_STARTER } from "./constants";
import { AllPlanSlugType, IGetPlanAliasProps, PlanSlugType } from "./types";

export function getRawPlanAlias(planAlias: string): PlanSlugType {
  return planAlias
    .replace("-cloud", "")
    .replace("-self-hosted", "")
    .replace("-with-dwh", "")
    .replace("-yearly", "") as PlanSlugType;
}

export function isProPlan(slug: string): boolean {
  return slug.includes("pro-");
}

export function isEnterprisePlan(slug: string): boolean {
  return slug.includes("enterprise-");
}

export function isDWHPlan(slug: string): boolean {
  return slug.includes("-with-dwh");
}

export function isSelfHostedPlan(slug: string): boolean {
  return !(slug.includes("-cloud") || slug.includes(PLAN_STARTER));
}

export function isYearlyPlan(slug: string): boolean {
  return slug.includes("-yearly");
}

export function getPlanAlias({
  planSlug,
  isSelfHosted,
  isDWH,
  isAnnualBilling,
}: IGetPlanAliasProps): AllPlanSlugType {
  const deploymentStr = isSelfHosted ? "-self-hosted" : "-cloud";
  const dwhStr = !isSelfHosted && isDWH ? "-with-dwh" : "";
  const billingStr = isAnnualBilling ? "-yearly" : "";

  // starter OR pro
  const rawPlanSlug = planSlug
    // remove hosting
    .replace("-cloud", "")
    .replace("-self-hosted", "")
    // remove DWH
    .replace("-with-dwh", "")
    // remove billing frequency
    .replace("-yearly", "");

  // plan options
  const planSuffix = planSlug.includes(PLAN_STARTER)
    ? `${dwhStr}${billingStr}`
    : `${deploymentStr}${dwhStr}${billingStr}`;

  return `${rawPlanSlug}${planSuffix}` as AllPlanSlugType;
}
