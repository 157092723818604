import { useRouter } from "next/router";
import { ReactElement, useCallback } from "react";
import { useHeaderNavLinkStyles, useLogoLinkStyles } from "./HeaderNav.styled";
import {
  persistUTMQueryParamsToString,
  persistUTMQueryParamsToUrlObject,
} from "@common/utils/router";
import {
  Badge,
  Box,
  Burger,
  Flex,
  Header,
  MediaQuery,
  NavLink,
  useMantineTheme,
} from "@mantine/core";
import {
  HEADER_NAV_HEIGHT,
  HEADER_NAV_HEIGHT_WITH_MESSAGE,
} from "@components/navigation/HeaderNav/constants";
import { IHeaderNavProps } from "./types";
import { getEnv, isProduction } from "@common/utils/config";
import Link from "next/link";
import { SECTION_BILLING_ADDRESS } from "@pages/account/manage/billing";
import { customerStore } from "@stores/customer";
import { paymentMethodsStore } from "@stores/payment-methods";
import LogoMetabase from "@common/components/icons/LogoMetabase";
import LogoMetabaseStore from "@common/components/icons/LogoMetabaseStore";
import Logo from "@common/components/icons/Logo";
import { purchasesStore } from "@stores/purchases";
import { HeaderNavBanner } from "./HeaderNav.components";
import { pluralize } from "@common/utils/string";
import { sessionStore } from "@stores/session";
import { useAccount } from "@stores/session/hooks";

export default function HeaderNav({
  opened,
  onBurgerClick,
}: IHeaderNavProps): ReactElement {
  const theme = useMantineTheme();
  const { classes: logoLinkClasses } = useLogoLinkStyles();
  const { classes: headerNavLinkStyles } = useHeaderNavLinkStyles();

  const router = useRouter();
  const { pathname, query } = router;
  const { step } = query;

  // customer
  const { isLoading: customerDetailsLoading, data: customerDetails } =
    customerStore.useGetDetails();

  // purchases
  const { isLoading: loadingPurchases, data: purchases } =
    purchasesStore.useGetPurchases();

  // payment
  const { isLoading: loadingDefaultPaymentMethod, data: defaultPaymentMethod } =
    paymentMethodsStore.useDefaultPaymentMethod();

  // session
  const account = useAccount();
  const { mutate: logout } = sessionStore.useLogout();

  // logout
  const logoutCallback = useCallback(() => {
    logout(null, {
      onSuccess() {
        const redirectTo = encodeURIComponent(router.asPath);
        router.push(
          persistUTMQueryParamsToUrlObject(router, "/", { redirectTo }),
        );
      },
    });
  }, [logout, router]);

  // are we in one of the checkout pages? `/checkout`
  const isSuccessStep = step === "success";
  const isCheckoutPathname = !!(pathname.indexOf("/checkout") > -1);
  const showAccountLink = !isCheckoutPathname || !!purchases?.length;

  // purchases
  const purchaseInTrial = purchases?.find(
    ({ hosted_instance, subscription }) =>
      subscription.in_trial && !!hosted_instance,
  );

  // banners
  const canHaveBanner = !isCheckoutPathname && !customerDetailsLoading;
  const hasTrialBanner =
    canHaveBanner && !loadingPurchases && !!purchaseInTrial;
  const hasBillingAddressBanner =
    canHaveBanner &&
    !loadingDefaultPaymentMethod &&
    !!defaultPaymentMethod &&
    !customerDetails?.billing_address;
  const hasBanner = hasTrialBanner || hasBillingAddressBanner;

  return (
    <Header
      id="main-header"
      height={hasBanner ? HEADER_NAV_HEIGHT_WITH_MESSAGE : HEADER_NAV_HEIGHT}
    >
      {hasTrialBanner ? (
        <HeaderNavBanner
          text={
            purchaseInTrial.subscription.trial_end_days
              ? `${purchaseInTrial.subscription.trial_end_days} ${pluralize("day", purchaseInTrial.subscription.trial_end_days)} left in your trial.`
              : "Your trial ends soon."
          }
          href={
            pathname === "/account/manage/plans"
              ? undefined
              : "/account/manage/plans"
          }
          hrefText="Buy now"
        />
      ) : (
        hasBillingAddressBanner && (
          <HeaderNavBanner
            text="Looks like we don't have a billing address."
            href={`/account/manage/billing#section=${SECTION_BILLING_ADDRESS}`}
            hrefText="Add a billing address"
          />
        )
      )}

      <Flex
        align="center"
        direction="row"
        justify="space-between"
        wrap="nowrap"
      >
        <Flex align="center" direction="row" wrap="nowrap" ml="lg">
          <Link
            href={persistUTMQueryParamsToUrlObject(
              router,
              account ? "/account" : "/",
            )}
            className={logoLinkClasses.root}
          >
            <Flex
              align="center"
              direction="row"
              justify="center"
              h="100%"
              px="sm"
            >
              <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                <Box ml={-9} mt={-1}>
                  <Logo height={41} />
                </Box>
              </MediaQuery>
              <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
                <Box>{account ? <LogoMetabaseStore /> : <LogoMetabase />}</Box>
              </MediaQuery>
            </Flex>
          </Link>

          {!isProduction() && (
            <Badge size="xs" bg="brand.3">
              {getEnv(true)}
            </Badge>
          )}
        </Flex>

        {account && (
          <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
            <Flex
              align="baseline"
              direction="row"
              justify="space-between"
              pr="lg"
              wrap="nowrap"
            >
              {isSuccessStep && (
                <>
                  <NavLink
                    component="a"
                    className={headerNavLinkStyles.root}
                    label="Docs"
                    icon={undefined}
                    href={persistUTMQueryParamsToString(
                      router,
                      "https://metabase.com/docs",
                    )}
                    target="_blank"
                    rel="noreferrer"
                  />
                  <NavLink
                    component={Link}
                    className={headerNavLinkStyles.root}
                    label="Support"
                    icon={undefined}
                    href={persistUTMQueryParamsToString(
                      router,
                      "https://www.metabase.com/help-premium",
                    )}
                    target="_blank"
                    rel="noreferrer"
                  />
                  <NavLink
                    component={Link}
                    className={headerNavLinkStyles.root}
                    label="Account"
                    icon={undefined}
                    href={persistUTMQueryParamsToString(router, "/account")}
                  />
                </>
              )}
              {showAccountLink && (
                <>
                  <NavLink
                    component={Link}
                    className={`${headerNavLinkStyles.root} ${headerNavLinkStyles.main}`}
                    label={`Logged in as ${account.first_name}`}
                    href="/account/manage"
                  />
                  <NavLink
                    data-testid="nav-link-logout"
                    className={`${headerNavLinkStyles.root} ${headerNavLinkStyles.main}`}
                    label="Logout"
                    icon={undefined}
                    onClick={logoutCallback}
                  />
                </>
              )}
            </Flex>
          </MediaQuery>
        )}

        {!account && (
          <div>
            <NavLink
              component={Link}
              className={`${headerNavLinkStyles.root} ${headerNavLinkStyles.main}`}
              pr="lg"
              label="Manage Metabase account"
              icon={undefined}
              href={persistUTMQueryParamsToString(router, "/")}
              data-testid="nav-link-login"
            />
          </div>
        )}

        {account && !isCheckoutPathname && (
          <MediaQuery largerThan="sm" styles={{ display: "none" }}>
            <Burger
              opened={opened}
              onClick={onBurgerClick}
              size="sm"
              color={theme.colors.gray[7]}
              mr="xl"
            />
          </MediaQuery>
        )}
      </Flex>
    </Header>
  );
}
